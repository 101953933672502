// Colors
$primary: #5bbebf;
$secondary: #4d8ac4;

// Fonts
$font-family-base: Lato, Sans-Serif;
$headings-font-family: Lato, Sans-Serif;
$headings-font-weight: normal;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.05;
$h6-font-size: $font-size-base;

// Global - Do not remove!
$enable-negative-margins: true;
$card-cap-padding-y: 1rem;
$placeholder-opacity-max: 0.2;
$placeholder-opacity-min: 0.1;
